import React from 'react';
import classNames from 'classnames';

const ns = 'global-wrapper';

const Layout = ({ location, title, children }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  });
  return (
    <div className={rootClassnames}>
      <main>{children}</main>
    </div>
  );
};

export default Layout;
