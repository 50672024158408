import React from 'react';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const ns = 'header';

const Header = ({ slideUp }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
    [`${ns}--slide-up`]: slideUp,
  });

  return (
    <div className={rootClassnames}>
      <div className={`${ns}__left-menu`}>
        <Link to="/work">
          <StaticImage src="../../images/WORKS.png" alt="works image" placeholder="blurred" />
        </Link>
        <Link to="/cv">
          <StaticImage src="../../images/CV.png" alt="cv image" placeholder="blurred" />
        </Link>
      </div>
      <div className={`${ns}__right-menu`}>
        <Link to="/contact">
          <StaticImage src="../../images/CONTACT.png" alt="cv image" placeholder="blurred" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
